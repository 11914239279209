import { apolloClient } from '@/vue-apollo' 
import NUMBER_OF_PENDING_FULFILLMENTS from '../graphql/getNumberOfPendingFulfillments.gql'
import { productTypes }  from '../shared/productTypes'
import { productTypeMapping } from '../shared/productTypeMapping'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    pendingOrders: {
      photologos: 0,
      lovemarks: 0,
      iconic: 0,
      initials: 0,
      extension: 0,
      metallic: 0,
      watercolor: 0,
      vintage: 0,
      photostyle: 0,
      social: 0,
      allPurpose: 0,
      smartEmail: 0,
      businessCard: 0,
      socialMediaKit: 0,
      qrBusinessCard: 0,
      avatarMe: 0,
      silverAndGold: 0,
      highlightCovers: 0,
      skinsBundle: 0,
      graphicAddon: 0,
      signatureBrand: 0,
      photologoSignatureBrand: 0,
      signatureBrandKit: 0,
      brandSeal: 0,
      signatureTutorial: 0,
      signaturePractice: 0,
      signatureVariationsTrio: 0,
      initialsPack: 0,
      brandSealPack: 0,
      kinetic: 0,
      electric: 0,
      animations: 0
    }
  },
  mutations:{
    setPendingOrders(state,pendingOrders){
      state.pendingOrders = pendingOrders
    },
  },
  actions: {
    async getPendingOrders({commit}) {
      let response = null
      try {
        response = await apolloClient.mutate({
          mutation: NUMBER_OF_PENDING_FULFILLMENTS,
            fetchPolicy: 'no-cache',
            variables: {
              fulfillment_statuses: ['WAITING_FOR_ASSIGN']
            }
        })
        
      } catch (error) {
        Vue.rollbar.error('Error with pending orders GraphQL mutation',error)
        console.log('Error with pending orders GraphQL mutation',error)
      }
      let pendingFulfillments =  {
        photologos: 0,
        lovemarks: 0,
        iconic: 0,
        initials: 0,
        extension: 0,
        metallic: 0,
        watercolor: 0,
        vintage: 0,
        photostyle: 0,
        social: 0,
        allPurpose: 0,
        smartEmail: 0,
        businessCard: 0,
        socialMediaKit: 0,
        qrBusinessCard: 0,
        avatarMe: 0,
        silverAndGold: 0,
        highlightCovers: 0,
        skinsBundle: 0,
        graphicAddon: 0,
        signatureBrand: 0,
        photologoSignatureBrand: 0,
        signatureBrandKit: 0,
        brandSeal: 0,
        signatureTutorial: 0,
        signaturePractice: 0,
        signatureVariationsTrio: 0,
        initialsPack: 0,
        brandSealPack: 0,
        kinetic: 0,
        electric: 0,
        animations: 0
      }
      if(response != null){
        if(response.data.echkoFulfillmentsCountByStatus.errors != null && response.data.echkoFulfillmentsCountByStatus.errors.length > 0){
          Vue.rollbar.error('Error with mutation pending orders',response.data.errors[0].message)
          console.log('Error',response.data.errors[0].message)
        }else{
          if(response.data.echkoFulfillmentsCountByStatus.fulfillmentsCount.length > 0){
            response.data.echkoFulfillmentsCountByStatus.fulfillmentsCount.forEach(element => {
              if(productTypes.photologo.type == element.productType){
                if(element.subBrand != null){
                  if(element.subBrand == productTypes.photologo.sub_brand)
                    pendingFulfillments.photologos = element.fulfillmentCount
                  if(element.subBrand == productTypes.lovemark.sub_brand)
                    pendingFulfillments.lovemarks = element.fulfillmentCount
                  if(element.subBrand == productTypes.iconic.sub_brand)
                    pendingFulfillments.iconic = element.fulfillmentCount
                  if(element.subBrand == productTypes.photologoSignatureBrand.sub_brand)
                    pendingFulfillments.photologoSignatureBrand = element.fulfillmentCount
                  if(element.subBrand == productTypes.initialsPack.sub_brand)
                    pendingFulfillments.initialsPack = element.fulfillmentCount
                  if(element.subBrand == productTypes.brandSealPack.sub_brand)
                    pendingFulfillments.brandSealPack = element.fulfillmentCount
                }
              }else{
                pendingFulfillments[productTypeMapping[element.productType]] += element.fulfillmentCount
              }   
            });
            commit('setPendingOrders',pendingFulfillments)
          }else{
            Vue.rollbar.error('Error with pending orders mutation empty array')
            console.log('Error with pending orders mutation empty array')
          }
        }
      }else{
        Vue.rollbar.error('Error with pending orders mutation null response')
        console.log('Error with pending orders mutation null response')
      }
    }
  }
}