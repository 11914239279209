export const productTypes = {
  photologo: {
    type: 'Photologo',
    name: 'Photologo',
    repRoute: 'photologo',
    sku: 'SE-00-PLG-SLG',
    detailName: 'D Detail',
    detailRepName: 'Detail',
    artRoute: 'photologos',
    sub_brand: 'photologo',
    pendingFulfillmentsKey: 'photologos' 
  },
  lovemark: {
    type: 'Photologo',
    name: 'Lovemark',
    repRoute: 'lovemark',
    sku: 'SE-00-PLG-SLG',
    detailName: 'L Detail',
    detailRepName: 'Lovemark Detail',
    artRoute: 'lovemarks',
    sub_brand: 'lovemark',
    pendingFulfillmentsKey: 'lovemarks'
  },
  iconic: {
    type: 'Photologo',
    name: 'Iconic Photologo',
    repRoute: 'iconic-photologo',
    sku: 'SE-00-PLG-SLG',
    detailName: 'IC Detail',
    detailRepName: 'Iconic Photologo Detail',
    artRoute: 'pl-iconic',
    sub_brand: 'iconic-photologo',
    pendingFulfillmentsKey: 'iconic'
  },
  initialsPack: {
    type: 'Photologo',
    name: 'Initials Pack',
    repRoute: 'initials-pack',
    sku: 'SE-00-PLG-SLG',
    detailName: 'INP Detail',
    detailRepName: 'Initials Pack Detail',
    artRoute: 'pl-initials-pack',
    sub_brand: 'initials-pack',
    pendingFulfillmentsKey: 'initialsPack'
  },
  brandSealPack: {
    type: 'Photologo',
    name: 'Brand Seal Pack',
    repRoute: 'brand-seal-pack',
    sku: 'SE-00-PLG-SLG',
    detailName: 'BSP Detail',
    detailRepName: 'Brand Seal Pack Detail',
    artRoute: 'pl-brand-seal-pack',
    sub_brand: 'brand-seal-pack',
    pendingFulfillmentsKey: 'brandSealPack'
  },
  initials: {
    type: 'Initials Edition',
    name: 'Initials Edition',
    repRoute: 'initials-edition',
    sku: 'SE-01-PLG-INI',
    detailName: 'I Detail',
    detailRepName: 'Initials Detail',
    artRoute: 'initials',
    pendingFulfillmentsKey: 'initials'
  },
  extension: {
    type: 'Extension kit',
    name: 'Extension kit',
    repRoute: 'extension-kit',
    sku: 'SE-01-PLG-EXK',
    detailName: 'E Detail',
    detailRepName: 'Extension Kit Detail',
    artRoute: 'extension',
    pendingFulfillmentsKey: 'extension'
  },
  metallic: {
    type: 'Metallic edition',
    name: 'Metallic edition',
    repRoute: 'metallic-edition',
    sku: 'SE-01-PLG-MET',
    detailName: 'M Detail',
    detailRepName: 'Metallic Edition Detail',
    artRoute: 'metallic',
    pendingFulfillmentsKey: 'metallic'
  },
  watercolor: {
    type: 'Photologo Watercolor Edition',
    name: 'Watercolor Edition',
    repRoute: 'watercolor-edition',
    sku: 'SE-01-PLG-WAT',
    detailName: 'W Detail',
    detailRepName: 'Watercolor Edition Detail',
    artRoute: 'watercolor',
    pendingFulfillmentsKey: 'watercolor'
  },
  vintage: {
    type:'Photologo Vintage Edition',
    name: 'Vintage Edition',
    repRoute: 'vintage-edition',
    sku: 'SE-01-PLG-VIN',
    detailName: 'V Detail',
    detailRepName: 'Vintage Edition Detail',
    artRoute: 'vintage',
    pendingFulfillmentsKey: 'vintage'
  },
  photostyle: {
    type:'Photologo Photostyle Edition',
    name: 'Photostyle Edition',
    repRoute: 'photostyle-edition',
    sku: 'SE-01-PLG-PST',
    detailName: 'Ps Detail',
    detailRepName: 'Photostyle Edition Detail',
    artRoute: 'photostyle',
    pendingFulfillmentsKey: 'photostyle'
  },
  social: {
    type:'Photologo Social Profile Pack',
    name: 'Social Profile Pack',
    repRoute: 'social-profile-pack',
    sku: 'SE-01-PLG-SPK',
    detailName: 'So Detail',
    detailRepName: 'Social Profile Pack Detail',
    artRoute: 'social-profile',
    pendingFulfillmentsKey: 'social'
  },
  AllPurpose: {
    type:'All-Purpose Logo',
    name: 'All-Purpose Logo Pack',
    repRoute: 'all-purpose',
    sku: 'SE-01-PLG-APL',
    detailName: 'Alp Detail',
    detailRepName: 'All Purpose Pack Detail',
    artRoute: 'all-purpose-pack',
    pendingFulfillmentsKey: 'allPurpose'
  },
  smartEmail: {
    type:'Custom Smart Email Signature',
    name: 'Custom Smart Email Signature',
    repRoute: 'smart-email',
    sku: 'SE-02-PLG-SES',
    detailName: 'Sm Detail',
    detailRepName: 'Smart Email Signature Detail',
    artRoute: 'smart-email-signature',
    pendingFulfillmentsKey: 'smartEmail'
  },
  businessCard: {
    type:'Custom Business Card',
    name: 'Business Card',
    repRoute: 'business-card',
    sku: 'SE-02-PLG-BCD',
    detailName: 'Bc Detail',
    detailRepName: 'Business Card Detail',
    artRoute: 'smart-business-card',
    pendingFulfillmentsKey: 'businessCard'
  },
  socialMediaKit: {
    type:'Custom Social Media Profile Templates',
    name: 'Social Media Kit',
    repRoute: 'social-media-kit',
    sku: 'SE-02-PLG-SMT',
    detailName: 'Smk Detail',
    detailRepName: 'Social Media Kit Detail',
    artRoute: 'social-media',
    pendingFulfillmentsKey: 'socialMediaKit'
  },
  qrBusinessCard: {
    type:'QR Business Card',
    name: 'QR Business Card',
    repRoute: 'qr-business-card',
    sku: 'SE-02-PLG-QRC',
    detailName: 'QR Detail',
    detailRepName: 'QR Business Card Detail',
    artRoute: 'qr-card',
    pendingFulfillmentsKey: 'qrBusinessCard'
  },
  avatarMe: {
    type:'AvatarME',
    name: 'Avatar Me',
    repRoute: 'avatar-me',
    sku: 'SE-02-PLG-AVM',
    detailName: 'Av Detail',
    detailRepName: 'Avatar Me Detail',
    artRoute: 'avatarme',
    pendingFulfillmentsKey: 'avatarMe'
  },
  kinetic: {
    type:'3D Kinetic Logo Animation',
    name: '3D Kinetic Logo',
    repRoute: 'kinetic',
    sku: 'SE-01-PLG-KLA',
    detailName: 'Ki Detail',
    detailRepName: 'Kinetic Detail',
    artRoute: '3d-kinetic',
    pendingFulfillmentsKey: 'kinetic'
  },
  electric: {
    type:'Electric Signature Animation',
    name: 'Electric Logo',
    repRoute: 'electric',
    sku: 'SE-01-PLG-ELA',
    detailName: 'Elc Detail',
    detailRepName: 'Electric Detail',
    artRoute: 'electric-logo',
    pendingFulfillmentsKey: 'electric'
  },
  silverAndGold: {
    type:'Silver & Gold Photologo',
    name: 'Silver & Gold',
    repRoute: 'silver-and-gold',
    sku: 'SE-02-PLG-SAG',
    detailName: 'Sg Detail',
    detailRepName: 'Silver and Gold Detail',
    artRoute: 'silver-gold',
    pendingFulfillmentsKey: 'silverAndGold'
  },
  highlightCovers: {
    type:'Custom Highlight Covers',
    name: 'Hightlight Covers',
    repRoute: 'highlight-covers',
    sku: 'SE-00-PLG-HLC',
    detailName: 'Hc Detail',
    detailRepName: 'Highlight Covers Detail',
    artRoute: 'highlight-cover',
    pendingFulfillmentsKey: 'highlightCovers'
  },
  skinsBundle: {
    type: 'Photologo Skins Bundle',
    name: 'Skins Bundle',
    repRoute: 'skins-bundle',
    sku: 'SE-01-PLG-PSB',
    detailName: 'Sb Detail',
    detailRepName: 'Skins Bundle Detail',
    artRoute: 'photologo-skins-bundle',
    pendingFulfillmentsKey: 'skinsBundle'
  },
  graphicAddon: {
    type: 'Graphic Add-on',
    name: 'Graphic Add-on',
    repRoute: 'graphic-addon',
    sku: 'SE-02-PLG-GAO',
    skuPLSale: 'SE-01-PLG-GAO',
    detailName: 'Ga Detail',
    detailRepName: 'Graphic Add-on Detail',
    artRoute: 'graphic-add-on',
    pendingFulfillmentsKey: 'graphicAddon'
  },
  photologoSignatureBrand: {
    type: 'Photologo',
    name: 'Photologo Signature Brand',
    repRoute: 'photologo-signature-brand',
    sku: 'SE-00-PLG-SLG',
    detailName: 'PhSB Detail',
    detailRepName: 'Photologo Signature Brand Detail',
    artRoute: 'photologos-signature-brand',
    sub_brand: 'signature-brand',
    pendingFulfillmentsKey: 'photologoSignatureBrand'
  },
  signatureBrand: {
    type: 'Signature Brand',
    name: 'Signature Brand',
    repRoute: 'signature-brand',
    sku: 'SE-01-PLG-SGB',
    detailName: 'Sgb Detail',
    detailRepName: 'Signature Brand Detail',
    artRoute: 'signaturebrand',
    pendingFulfillmentsKey: 'signatureBrand'
  },
  signatureBrandKit:{
    type: 'Signature Brand Kit',
    name: 'Signature Brand Kit',
    repRoute: 'signature-brand-kit',
    detailName: 'SBK Detail',
    detailRepName: 'Signature Brand Kit Detail',
    artRoute: 'sb-kit',
    pendingFulfillmentsKey: 'signatureBrandKit',
  },
  businessCardsKit:{
    name: 'Business Cards Kit',
    sku: 'SE-01-PLG-BCK',
  },
  emailSignatureKit:{
    name: 'Email Signature Kit',
    sku: 'SE-01-PLG-ESK',
  },
  expansionKit:{
    name: 'Expansion Kit',
    sku: 'SE-01-PLG-EPK',
  },
  signatureSocialMediaKit:{
    name: 'Social Media Kit',
    sku: 'SE-01-PLG-SMK',
  },
  stationeryKit:{
    name: 'Stationery Kit',
    sku: 'SE-01-PLG-STK',
  },
  videoKit:{
    name: 'Video Kit',
    sku: 'SE-01-PLG-VIK',
  },
  websiteAssetsKit:{
    name: 'Website Assets Kit',
    sku: 'SE-01-PLG-WAK',
  },
  brandSeal: {
    type: 'Brand Seal',
    name: 'Brand Seal',
    repRoute: 'brand-seal',
    sku: 'SE-01-PLG-BRS',
    detailName: 'BRS Detail',
    detailRepName: 'Brand Seal Detail',
    artRoute: 'pl-brand-seal',
    pendingFulfillmentsKey: 'brandSeal'
  },
  signatureTutorial: {
    type: 'Signature Tutorial',
    name: 'Signature Tutorial',
    repRoute: 'signature-tutorial',
    sku: 'SE-01-PLG-TUT',
    detailName: 'Sgt Detail',
    detailRepName: 'Signature Tutorial Detail',
    artRoute: 'pl-signature-tutorial',
    pendingFulfillmentsKey: 'signatureTutorial'
  },
  signaturePractice: {
    type: 'Signature Practice PDF',
    name: 'Signature Practice PDF',
    repRoute: 'signature-practice-pdf',
    sku: 'SE-01-PLG-PRA',
    detailName: 'Spf Detail',
    detailRepName: 'Signature Practice PDF Detail',
    artRoute: 'signature-practice',
    pendingFulfillmentsKey: 'signaturePractice'
  },
  signatureVariationsTrio: {
    type: 'Signature Variations Trio',
    name: 'Signature Variations Trio',
    repRoute: 'signature-variations-trio',
    sku: 'SE-01-PLG-SVT',
    detailName: 'SVT Detail',
    detailRepName: 'Signature Variations Trio Detail',
    artRoute: 'sig-variations-trio',
    pendingFulfillmentsKey: 'signatureVariationsTrio'
  },
  animations: {
    type:'Animated Photologo',
    name: 'Animated Photologo',
    repRoute: 'animations',
    sku: 'SE-01-PLG-SAN',
    detailName: 'A Detail',
    detailRepName: 'Animations Detail',
    artRoute: 'animators',
    pendingFulfillmentsKey: 'animations'
  },
  stamps: {
    type: 'Photostamp',
    name: 'Photostamp',
    repRoute: 'stamps',
    sku: 'EN-00-PLG-STM',
    pendingFulfillmentsKey: 'stamps'
  }
}