import { apolloClient } from '@/vue-apollo' 
import NUMBER_OF_PENDING_VERSIONS from '../graphql/getNumberofPendingVersions.gql'
import { productTypes }  from '../shared/productTypes'
import { productTypeMapping } from '../shared/productTypeMapping'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    pendingVersions: {
      photologos: 0,
      lovemarks: 0,
      iconic: 0,
      initials: 0,
      extension: 0,
      metallic: 0,
      watercolor: 0,
      vintage: 0,
      photostyle: 0,
      social: 0,
      allPurpose: 0,
      smartEmail: 0,
      businessCard: 0,
      socialMediaKit: 0,
      qrBusinessCard: 0,
      avatarMe: 0,
      silverAndGold: 0,
      highlightCovers: 0,
      skinsBundle: 0,
      graphicAddon: 0,
      signatureBrand: 0,
      photologoSignatureBrand: 0,
      signatureBrandKit: 0,
      brandSeal: 0,
      signatureTutorial: 0,
      signaturePractice: 0,
      signatureVariationsTrio: 0,
      initialsPack: 0,
      brandSealPack: 0,
      kinetic: 0,
      electric: 0,
      animations: 0
    }
  },
  mutations:{
    setPendingVersions(state,pendingVersions){
      state.pendingVersions = pendingVersions
    },
  },
  actions: {
    async getPendingVersions({commit}, user) {
      const designersProducts = [
        productTypes.photologo.type,
        productTypes.initials.type,
        productTypes.extension.type,
        productTypes.metallic.type,
        productTypes.watercolor.type,
        productTypes.vintage.type,
        productTypes.photostyle.type,
        productTypes.social.type,
        productTypes.AllPurpose.type,
        productTypes.smartEmail.type,
        productTypes.businessCard.type,
        productTypes.socialMediaKit.type,
        productTypes.qrBusinessCard.type,
        productTypes.avatarMe.type,
        productTypes.silverAndGold.type,
        productTypes.highlightCovers.type,
        productTypes.skinsBundle.type,
        productTypes.graphicAddon.type,
        productTypes.signatureBrand.type,
        productTypes.photologoSignatureBrand.type,
        productTypes.signatureBrandKit.type,
        productTypes.brandSeal.type,
        productTypes.signatureTutorial.type,
        productTypes.signaturePractice.type,
        productTypes.signatureVariationsTrio.type,
        productTypes.initialsPack.type,
        productTypes.brandSealPack.type
      ].join(',');


      const animatorProducts =  [
        productTypes.animations.type,
        productTypes.kinetic.type,
        productTypes.electric.type
      ].join(',');

      const products = user.group === 'Designers' ? designersProducts : user.group === 'Animators' ? animatorProducts : null
      let response = null
      try {
        response = await apolloClient.query({
          query: NUMBER_OF_PENDING_VERSIONS,
            fetchPolicy: 'network-only',
            variables: {
              completed: false,
              user: user.username,
              productType: products,
              status: 'assigned,representative_approval'
            }
        })
        
      } catch (error) {
        Vue.rollbar.error('Error with pending versions GraphQL query',error)
        console.log('Error with pending versions GraphQL query',error)
      }
      let pendingVersions =  {
        photologos: 0,
        lovemarks: 0,
        iconic: 0,
        initials: 0,
        extension: 0,
        metallic: 0,
        watercolor: 0,
        vintage: 0,
        photostyle: 0,
        social: 0,
        allPurpose: 0,
        smartEmail: 0,
        businessCard: 0,
        socialMediaKit: 0,
        qrBusinessCard: 0,
        avatarMe: 0,
        silverAndGold: 0,
        highlightCovers: 0,
        skinsBundle: 0,
        graphicAddon: 0,
        signatureBrand: 0,
        photologoSignatureBrand: 0,
        signatureBrandKit: 0,
        brandSeal: 0,
        signatureTutorial: 0,
        signaturePractice: 0,
        signatureVariationsTrio: 0,
        initialsPack: 0,
        brandSealPack: 0,
        kinetic: 0,
        electric: 0,
        animations: 0
      }
      if(response != null){
          if(response.data.versions.countByProductType.length > 0){
            response.data.versions.countByProductType.forEach(element => {
              if(element.productType === productTypes.photologo.type){
                if(element.subBrand != null){
                  if(element.subBrand == productTypes.photologo.sub_brand)
                    pendingVersions.photologos = element.count
                  if(element.subBrand == productTypes.lovemark.sub_brand)
                    pendingVersions.lovemarks = element.count
                  if(element.subBrand == productTypes.iconic.sub_brand)
                    pendingVersions.iconic = element.count
                  if(element.subBrand == productTypes.photologoSignatureBrand.sub_brand)
                    pendingVersions.photologoSignatureBrand = element.count
                  if(element.subBrand == productTypes.initialsPack.sub_brand)
                    pendingVersions.initialsPack = element.count
                  if(element.subBrand == productTypes.brandSealPack.sub_brand)
                    pendingVersions.brandSealPack = element.count
                }
              }else{
                pendingVersions[productTypeMapping[element.productType]] += element.count
              }
            });
            commit('setPendingVersions',pendingVersions)
          }else{
            //Vue.rollbar.error('Error with pending versions query empty array')
            console.log('countByProductType empty array')
          }
      }else{
        Vue.rollbar.error('Error with pending versions query null response')
        console.log('Error with pending versions query null response')
      }
    }
  }
}